import React from 'react'

export const LogoIcon = () => {
    return (
        <svg width="115" height="31" viewBox="0 0 115 31" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23.9166 4H4.08335C1.83165 4 0 5.84302 0 8.10866V21.0217C0 23.2873 1.83165 25.1304 4.08335 25.1304H5.83335V30.413C5.83335 30.6408 5.964 30.8486 6.16935 30.9448C6.2475 30.9824 6.3327 31 6.4167 31C6.55085 31 6.68385 30.9531 6.79005 30.8638L13.6279 25.1304H23.9168C26.1684 25.1304 28 23.2873 28 21.0217V8.10866C28 5.84302 26.1684 4 23.9166 4ZM21 18.6739C21 18.8911 20.881 19.0894 20.692 19.1916C20.6056 19.2385 20.5111 19.2608 20.4166 19.2608C20.3035 19.2608 20.1903 19.228 20.0935 19.1622L17.5 17.4225V18.6739C17.5 18.9979 17.2386 19.2608 16.9166 19.2608H8.75C7.049 19.2608 7 15.0453 7 14.5652C7 14.0851 7.049 9.86958 8.75 9.86958H16.9166C17.2386 9.86958 17.5 10.1326 17.5 10.4566V11.7079L20.0935 9.96819C20.272 9.84729 20.5041 9.83673 20.692 9.93886C20.8821 10.041 21 10.2394 21 10.4566V18.6739Z" fill="white"/>
            <path d="M43.8823 23.8031C43.3745 23.8031 42.914 23.6779 42.5007 23.4273C42.0992 23.1767 41.7981 22.8248 41.5973 22.3714L36.2126 9.91499C36.0709 9.59284 36 9.27069 36 8.94855C36 8.63833 36.059 8.34004 36.1771 8.05369C36.2952 7.75541 36.4605 7.49292 36.6731 7.26622C36.8857 7.03952 37.1336 6.86055 37.417 6.72931C37.7123 6.59806 38.0252 6.53244 38.3558 6.53244C38.8046 6.53244 39.2238 6.65772 39.6135 6.90828C40.0031 7.15884 40.2984 7.50485 40.4991 7.94631L43.7583 15.8031L46.9998 7.96421C47.1769 7.53468 47.4603 7.19463 47.85 6.94407C48.2397 6.68158 48.6648 6.55034 49.1253 6.55034C49.7984 6.55034 50.3652 6.79493 50.8258 7.28412C51.0383 7.51081 51.2037 7.76734 51.3217 8.05369C51.4398 8.34004 51.4989 8.63236 51.4989 8.93065C51.5107 9.217 51.4398 9.55108 51.2863 9.93289L45.937 22.3714C45.7481 22.777 45.4824 23.1111 45.1399 23.3736C44.7975 23.6361 44.3783 23.7793 43.8823 23.8031Z" fill="white"/>
            <path d="M56.4762 5.08277C55.7913 5.08277 55.2009 4.83221 54.7049 4.3311C54.4688 4.09247 54.2798 3.81805 54.1381 3.50783C54.0082 3.19761 53.9433 2.87547 53.9433 2.54139C53.9433 1.84937 54.1972 1.24683 54.7049 0.733781C55.1891 0.244594 55.7795 0 56.4762 0C56.8187 0 57.1434 0.0656224 57.4504 0.196868C57.7575 0.316182 58.0291 0.495153 58.2652 0.733781C58.7612 1.2349 59.0092 1.83743 59.0092 2.54139C59.0092 3.23341 58.7612 3.82998 58.2652 4.3311C58.0291 4.56972 57.7575 4.75466 57.4504 4.88591C57.1434 5.01715 56.8187 5.08277 56.4762 5.08277ZM56.4762 24C56.1574 24 55.8504 23.9344 55.5552 23.8031C55.2717 23.6838 55.0238 23.5168 54.8112 23.302C54.5868 23.0872 54.4097 22.8367 54.2798 22.5503C54.1499 22.2521 54.085 21.9478 54.085 21.6376V8.89486C54.085 8.58464 54.1499 8.28635 54.2798 8C54.4097 7.70172 54.5868 7.44519 54.8112 7.23042C55.0238 7.01566 55.2777 6.84862 55.5729 6.72931C55.8799 6.59806 56.1928 6.53244 56.5117 6.53244C56.8187 6.53244 57.1139 6.59806 57.3973 6.72931C57.6925 6.84862 57.9464 7.01566 58.159 7.23042C58.3715 7.44519 58.5309 7.69575 58.6372 7.9821C58.7553 8.26846 58.8143 8.57271 58.8143 8.89486V21.6376C58.8143 21.9478 58.7494 22.2521 58.6195 22.5503C58.5014 22.8367 58.3361 23.0872 58.1235 23.302C57.911 23.5168 57.663 23.6838 57.3796 23.8031C57.0962 23.9344 56.7951 24 56.4762 24Z" fill="white"/>
            <path d="M70.3101 24C69.0938 24 67.9484 23.7852 66.8738 23.3557C65.811 22.9142 64.8722 22.2998 64.0574 21.5123C63.2426 20.7129 62.6049 19.7823 62.1444 18.7204C61.6839 17.6585 61.4536 16.5309 61.4536 15.3378C61.4536 14.1327 61.6839 12.9933 62.1444 11.9195C62.6049 10.8337 63.2426 9.88516 64.0574 9.07383C64.8722 8.27442 65.8169 7.65399 66.8915 7.21253C67.9779 6.75914 69.1174 6.53244 70.3101 6.53244C71.491 6.53244 72.5951 6.73527 73.6224 7.14094C74.6498 7.53468 75.5354 8.11932 76.2794 8.89486C76.551 9.18121 76.7576 9.49739 76.8993 9.8434C77.0528 10.1894 77.1296 10.5354 77.1296 10.8814C77.1296 11.5377 76.8462 12.1104 76.2794 12.5996C76.0432 12.8024 75.7893 12.9575 75.5177 13.0649C75.2461 13.1723 74.9981 13.226 74.7738 13.226C74.3959 13.226 74.0475 13.1424 73.7287 12.9754C73.4099 12.8084 73.0438 12.5578 72.6305 12.2237C72.229 11.9135 71.8275 11.6987 71.426 11.5794C71.0363 11.4482 70.623 11.3826 70.1861 11.3826C69.6429 11.3826 69.1292 11.4899 68.6451 11.7047C68.1727 11.9075 67.7594 12.1939 67.4052 12.5638C67.0509 12.9217 66.7734 13.3393 66.5726 13.8166C66.3837 14.2819 66.2892 14.7711 66.2892 15.2841C66.2892 15.7375 66.3778 16.1909 66.5549 16.6443C66.7321 17.0977 66.98 17.5093 67.2989 17.8792C67.6413 18.2729 68.031 18.5831 68.4679 18.8098C68.9049 19.0365 69.3713 19.1559 69.8673 19.1678H70.0444C70.0798 19.1797 70.133 19.1857 70.2038 19.1857C70.6525 19.1857 71.0658 19.1201 71.4437 18.9888C71.8334 18.8576 72.229 18.6368 72.6305 18.3266C73.0202 18.0045 73.3744 17.7599 73.6933 17.5928C74.0121 17.4258 74.3605 17.3423 74.7383 17.3423C74.9745 17.3423 75.2284 17.396 75.5 17.5034C75.7716 17.5988 76.0255 17.7479 76.2617 17.9508C76.8285 18.44 77.1119 19.0186 77.1119 19.6868C77.1119 20.0328 77.041 20.3788 76.8993 20.7248C76.7576 21.0589 76.551 21.3632 76.2794 21.6376C75.5354 22.4131 74.6439 23.0037 73.6047 23.4094C72.5774 23.8031 71.4791 24 70.3101 24Z" fill="white"/>
            <path d="M87.5271 24C86.3581 24 85.2481 23.7793 84.1971 23.3378C83.1579 22.8844 82.2368 22.2521 81.4339 21.4407C80.6309 20.6413 80.005 19.7107 79.5563 18.6488C79.1075 17.5869 78.8832 16.4653 78.8832 15.2841C78.8832 14.1029 79.1075 12.9814 79.5563 11.9195C80.005 10.8456 80.6309 9.90306 81.4339 9.09172C82.2368 8.28039 83.1579 7.65399 84.1971 7.21253C85.2481 6.75914 86.3581 6.53244 87.5271 6.53244C88.6962 6.53244 89.8062 6.75914 90.8572 7.21253C91.92 7.66592 92.8469 8.29232 93.6381 9.09172C94.4293 9.90306 95.0493 10.8456 95.498 11.9195C95.9467 12.9814 96.1711 14.1029 96.1711 15.2841C96.1711 16.4653 95.9467 17.5869 95.498 18.6488C95.0611 19.6987 94.4411 20.6294 93.6381 21.4407C92.8469 22.2401 91.92 22.8665 90.8572 23.3199C89.8062 23.7733 88.6962 24 87.5271 24ZM87.5271 19.2215C88.0585 19.2215 88.5663 19.1201 89.0504 18.9172C89.5346 18.7144 89.9538 18.434 90.3081 18.0761C90.6741 17.7062 90.9576 17.2826 91.1583 16.8054C91.359 16.3281 91.4594 15.821 91.4594 15.2841C91.4594 14.7472 91.359 14.2401 91.1583 13.7629C90.9576 13.2856 90.6741 12.862 90.3081 12.4922C89.9538 12.1342 89.5346 11.8538 89.0504 11.651C88.5663 11.4482 88.0585 11.3468 87.5271 11.3468C87.0076 11.3468 86.5057 11.4482 86.0215 11.651C85.5492 11.8538 85.1359 12.1342 84.7816 12.4922C84.4155 12.862 84.1262 13.2856 83.9137 13.7629C83.7129 14.2401 83.6126 14.7472 83.6126 15.2841C83.6126 15.821 83.7129 16.3281 83.9137 16.8054C84.1262 17.2826 84.4155 17.7062 84.7816 18.0761C85.1359 18.434 85.5492 18.7144 86.0215 18.9172C86.5057 19.1201 87.0076 19.2215 87.5271 19.2215Z" fill="white"/>
            <path d="M100.404 23.8031C100.086 23.8031 99.7786 23.7435 99.4834 23.6242C99.1882 23.5048 98.9284 23.3378 98.704 23.123C98.2199 22.6696 97.9778 22.1208 97.9778 21.4765V15.0336C97.9778 13.8881 98.1963 12.7964 98.6332 11.7584C99.0701 10.7204 99.6783 9.80761 100.458 9.02013C101.237 8.23266 102.134 7.62416 103.15 7.19463C104.166 6.75317 105.24 6.53244 106.374 6.53244C107.519 6.53244 108.612 6.75317 109.651 7.19463C110.702 7.62416 111.629 8.23266 112.432 9.02013C113.235 9.80761 113.86 10.7204 114.309 11.7584C114.77 12.7964 115 13.8881 115 15.0336V21.4765C115 22.1327 114.782 22.6816 114.345 23.123C114.132 23.3378 113.884 23.5048 113.601 23.6242C113.317 23.7435 113.016 23.8031 112.697 23.8031C112.367 23.8031 112.054 23.7435 111.759 23.6242C111.475 23.5048 111.227 23.3378 111.015 23.123C110.542 22.6816 110.306 22.1327 110.306 21.4765V15.0515C110.294 14.5503 110.182 14.0731 109.97 13.6197C109.757 13.1544 109.462 12.7487 109.084 12.4027C108.718 12.0567 108.299 11.7882 107.826 11.5973C107.354 11.4064 106.87 11.311 106.374 11.311C105.878 11.311 105.405 11.4064 104.957 11.5973C104.508 11.7763 104.124 12.0268 103.805 12.349C103.475 12.6831 103.209 13.0768 103.008 13.5302C102.819 13.9717 102.719 14.443 102.707 14.9441V21.4765C102.707 22.1447 102.483 22.6935 102.034 23.123C101.585 23.5764 101.042 23.8031 100.404 23.8031Z" fill="white"/>
        </svg>
    )
}