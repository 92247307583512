import React from 'react'

const CalendarIcon = () => {
    return (
        <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5 5.625H3.75V6.875H2.5V5.625ZM2.5 8.125H3.75V9.375H2.5V8.125ZM5 5.625H6.25V6.875H5V5.625ZM5 8.125H6.25V9.375H5V8.125ZM7.5 5.625H8.75V6.875H7.5V5.625ZM7.5 8.125H8.75V9.375H7.5V8.125Z" fill="#333333"/>
            <path d="M1.25 12.5H10C10.6894 12.5 11.25 11.9394 11.25 11.25V3.75V2.5C11.25 1.81062 10.6894 1.25 10 1.25H8.75V0H7.5V1.25H3.75V0H2.5V1.25H1.25C0.560625 1.25 0 1.81062 0 2.5V3.75V11.25C0 11.9394 0.560625 12.5 1.25 12.5ZM10 3.75L10.0006 11.25H1.25V3.75H10Z" fill="#333333"/>
        </svg>
    )
}

const TimeIcon = () => {
    return (
        <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.5 0C3.05375 0 0.25 2.80375 0.25 6.25C0.25 9.69625 3.05375 12.5 6.5 12.5C9.94625 12.5 12.75 9.69625 12.75 6.25C12.75 2.80375 9.94625 0 6.5 0ZM6.5 11.25C3.74313 11.25 1.5 9.00688 1.5 6.25C1.5 3.49313 3.74313 1.25 6.5 1.25C9.25688 1.25 11.5 3.49313 11.5 6.25C11.5 9.00688 9.25688 11.25 6.5 11.25Z" fill="#333333"/>
            <path d="M7.125 3H5.875V6.38375L7.93313 8.44187L8.81687 7.55812L7.125 5.86625V3Z" fill="#333333"/>
        </svg>
    )
}

const PeopleIcon = () => {
    return (
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.9294 8.33045C11.8786 7.7263 10.6831 7.38253 9.44482 7.29047C9.69846 7.40396 9.95362 7.51487 10.1938 7.65295C10.4731 7.81369 10.6971 8.04567 10.8617 8.3148C11.4503 8.47826 12.0163 8.70661 12.5406 9.00792C12.6505 9.07124 12.7188 9.19828 12.7188 9.33981V11.1564H11.1563V11.9376H13.1094C13.3253 11.9376 13.5 11.7629 13.5 11.547V9.33981C13.5001 8.91906 13.2811 8.53226 12.9294 8.33045Z" fill="#333333"/>
            <path d="M9.80437 8.33045C7.34541 6.91671 4.02967 6.91671 1.57067 8.33045C1.21857 8.53301 1 8.91944 1 9.33983V11.547C1 11.763 1.17471 11.9377 1.39063 11.9377H9.98441C10.2003 11.9377 10.375 11.763 10.375 11.547V9.33944C10.375 8.91944 10.1565 8.53301 9.80437 8.33045ZM9.5938 11.1564H1.78128V9.33983C1.78128 9.1983 1.84957 9.07127 1.9598 9.00794C4.18301 7.73002 7.19207 7.73002 9.41528 9.00794C9.52553 9.07127 9.5938 9.1983 9.5938 9.33944V11.1564Z" fill="#333333" stroke="#333333" strokeWidth="0.3"/>
            <path d="M8.61791 1.79963C8.68271 1.79285 8.74689 1.78128 8.81246 1.78128C9.88937 1.78128 10.7656 2.65753 10.7656 3.73441C10.7656 4.81129 9.88934 5.68754 8.81246 5.68754C8.74694 5.68754 8.68275 5.67591 8.61795 5.66912C8.4522 5.91941 8.2493 6.13934 8.02678 6.33932C8.28117 6.41766 8.54367 6.46878 8.81246 6.46878C10.32 6.46878 11.5469 5.24198 11.5469 3.73439C11.5469 2.2268 10.3201 1 8.81246 1C8.54363 1 8.28112 1.05112 8.02673 1.12946C8.24925 1.32948 8.45216 1.54935 8.61791 1.79963Z" fill="#333333"/>
            <path d="M5.68703 6.46879C7.19459 6.46879 8.42141 5.24198 8.42141 3.73439C8.42141 2.2268 7.19461 1 5.68703 1C4.17944 1 2.95264 2.2268 2.95264 3.73439C2.95264 5.24198 4.17946 6.46879 5.68703 6.46879ZM5.68703 1.78126C6.76393 1.78126 7.64015 2.65751 7.64015 3.73439C7.64015 4.81128 6.76391 5.68753 5.68703 5.68753C4.61014 5.68753 3.7339 4.81128 3.7339 3.73439C3.7339 2.65751 4.61014 1.78126 5.68703 1.78126Z" fill="#333333" stroke="#333333" strokeWidth="0.3"/>
        </svg>

    )
}

const CaseIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6666 2.49996H8.91663V1.33329C8.91663 0.689876 8.39338 0.166626 7.74996 0.166626H4.24996C3.60654 0.166626 3.08329 0.689876 3.08329 1.33329V2.49996H1.33329C0.689876 2.49996 0.166626 3.02321 0.166626 3.66663V10.0833C0.166626 10.7267 0.689876 11.25 1.33329 11.25H10.6666C11.31 11.25 11.8333 10.7267 11.8333 10.0833V3.66663C11.8333 3.02321 11.31 2.49996 10.6666 2.49996ZM7.74996 1.33329V2.49996H4.24996V1.33329H7.74996ZM1.33329 3.66663H3.66663H8.33329H8.91663H10.6666V5.99996H8.91663V4.83329H7.74996V5.99996H4.24996V4.83329H3.08329V5.99996H1.33329V3.66663ZM1.33329 10.0833V7.16663H3.08329V8.33329H4.24996V7.16663H7.74996V8.33329H8.91663V7.16663H10.6672V10.0833H1.33329Z" fill="#2290E0"/>
        </svg>
    )
}

const PeopleBlueIcon = () => {
    return (
        <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.4521 7.78719C11.4433 7.20722 10.2957 6.8772 9.10693 6.78882C9.35043 6.89777 9.59538 7.00424 9.82598 7.1368C10.0941 7.29111 10.3091 7.51381 10.4672 7.77217C11.0322 7.92909 11.5755 8.1483 12.0789 8.43756C12.1844 8.49835 12.2499 8.62031 12.2499 8.75617V10.5001H10.7499V11.2501H12.6249C12.8322 11.2501 12.9999 11.0824 12.9999 10.8751V8.75617C12.9999 8.35225 12.7897 7.98093 12.4521 7.78719Z" fill="#2290E0"/>
            <path d="M9.45214 7.78717C7.09156 6.42999 3.90846 6.42999 1.54784 7.78717C1.20983 7.98163 1 8.35261 1 8.75618V10.8751C1 11.0824 1.16772 11.2501 1.375 11.2501H9.62498C9.83226 11.2501 9.99998 11.0824 9.99998 10.8751V8.75581C9.99998 8.35261 9.79015 7.98163 9.45214 7.78717ZM9.25 10.5001H1.75003V8.75618C1.75003 8.62031 1.81559 8.49836 1.92141 8.43757C4.05567 7.21076 6.94435 7.21076 9.07862 8.43757C9.18446 8.49836 9.25 8.62031 9.25 8.75581V10.5001Z" fill="#2290E0" stroke="#2290E0" strokeWidth="0.3"/>
            <path d="M8.31313 1.51764C8.37534 1.51113 8.43695 1.50003 8.4999 1.50003C9.53372 1.50003 10.3749 2.34122 10.3749 3.37502C10.3749 4.40882 9.5337 5.25001 8.4999 5.25001C8.437 5.25001 8.37538 5.23884 8.31317 5.23233C8.15405 5.4726 7.95927 5.68374 7.74565 5.87571C7.98986 5.95092 8.24186 5.99999 8.4999 5.99999C9.94716 5.99999 11.1249 4.82227 11.1249 3.375C11.1249 1.92772 9.94718 0.75 8.4999 0.75C8.24182 0.75 7.98982 0.799077 7.74561 0.874282C7.95922 1.0663 8.15401 1.27737 8.31313 1.51764Z" fill="#2290E0"/>
            <path d="M5.49951 6C6.94676 6 8.12451 4.82228 8.12451 3.375C8.12451 1.92772 6.94678 0.75 5.49951 0.75C4.05223 0.75 2.87451 1.92772 2.87451 3.375C2.87451 4.82228 4.05225 6 5.49951 6ZM5.49951 1.50001C6.53333 1.50001 7.3745 2.3412 7.3745 3.375C7.3745 4.4088 6.53331 5.25 5.49951 5.25C4.46571 5.25 3.62452 4.4088 3.62452 3.375C3.62452 2.3412 4.46571 1.50001 5.49951 1.50001Z" fill="#2290E0" stroke="#2290E0" strokeWidth="0.3"/>
        </svg>

    )
}

const PlayIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 8H2V20C2 21.103 2.897 22 4 22H16V20H4V8Z" fill="#757575"/>
            <path d="M20 2H8C6.896 2 6 2.896 6 4V16C6 17.104 6.896 18 8 18H20C21.104 18 22 17.104 22 16V4C22 2.896 21.104 2 20 2ZM11 14V6L18 10L11 14Z" fill="#757575"/>
        </svg>
    )
}

const HandIcon = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.05975 11.8181C6.68338 11.8181 6.37793 11.5127 6.37793 11.1363V2.95451C6.37793 1.82725 7.29512 0.909058 8.42338 0.909058C9.55164 0.909058 10.4688 1.82725 10.4688 2.95451V10.2273C10.4688 10.6037 10.1634 10.9091 9.78701 10.9091C9.41065 10.9091 9.1052 10.6037 9.1052 10.2273V2.95451C9.1052 2.57915 8.79975 2.27269 8.42338 2.27269C8.04702 2.27269 7.74156 2.57915 7.74156 2.95451V11.1363C7.74156 11.5127 7.43611 11.8181 7.05975 11.8181Z" fill="#757575"/>
            <path d="M12.5143 10.9091C12.138 10.9091 11.8325 10.6036 11.8325 10.2273V2.04545C11.8325 1.66992 11.5271 1.36363 11.1507 1.36363C10.7743 1.36363 10.4689 1.66992 10.4689 2.04545V3.6363C10.4689 4.01267 10.1634 4.31812 9.78705 4.31812C9.41068 4.31812 9.10522 4.01267 9.10522 3.6363V2.04545C9.10522 0.918189 10.0224 0 11.1507 0C12.279 0 13.1962 0.918189 13.1962 2.04545V10.2273C13.1962 10.6036 12.8907 10.9091 12.5143 10.9091Z" fill="#757575"/>
            <path d="M15.2414 10.9091C14.865 10.9091 14.5595 10.6036 14.5595 10.2273V3.86369C14.5595 3.48816 14.2541 3.18187 13.8777 3.18187C13.5014 3.18187 13.1959 3.48816 13.1959 3.86369V4.54551C13.1959 4.92187 12.8905 5.22732 12.5141 5.22732C12.1377 5.22732 11.8323 4.92187 11.8323 4.54551V3.86369C11.8323 2.73643 12.7495 1.81824 13.8777 1.81824C15.006 1.81824 15.9232 2.73643 15.9232 3.86369V10.2273C15.9232 10.6036 15.6177 10.9091 15.2414 10.9091Z" fill="#757575"/>
            <path d="M14.3325 19.9999H9.31688C7.83057 19.9999 6.42248 19.2146 5.64146 17.9481L1.70053 11.5581C1.6997 11.5562 1.6997 11.5554 1.69886 11.5546C1.48879 11.2336 1.37793 10.8526 1.37793 10.4545C1.37793 9.32719 2.29512 8.409 3.42338 8.409C3.79875 8.409 4.1578 8.50721 4.46609 8.69448C4.47691 8.7008 4.48789 8.70813 4.49971 8.71362L7.42424 10.5582C7.74151 10.7609 7.83689 11.1817 7.63597 11.5C7.43522 11.8181 7.01325 11.9126 6.69515 11.7127L3.79692 9.88366C3.7861 9.87717 3.77611 9.87085 3.76612 9.86452C3.67158 9.80543 3.54973 9.77263 3.42338 9.77263C3.04702 9.77263 2.74157 10.0791 2.74157 10.4545C2.74157 10.58 2.77336 10.699 2.82962 10.7935C2.83612 10.8027 2.84144 10.8118 2.84693 10.82L6.80151 17.2327C7.33601 18.0981 8.29965 18.6363 9.31688 18.6363H14.3325C15.9614 18.6363 17.287 17.3109 17.287 15.6818V4.77269C17.287 4.39716 16.9815 4.09087 16.6051 4.09087C16.2288 4.09087 15.9233 4.39716 15.9233 4.77269C15.9233 5.14905 15.6179 5.45451 15.2415 5.45451C14.8651 5.45451 14.5597 5.14905 14.5597 4.77269C14.5597 3.64542 15.4769 2.72723 16.6051 2.72723C17.7334 2.72723 18.6506 3.64542 18.6506 4.77269V15.6818C18.6506 18.0627 16.7133 19.9999 14.3325 19.9999Z" fill="#757575"/>
        </svg>
    )
}

const MonitorIcon = () => {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.9302 3H4.18604C3.03174 3 2.09302 3.897 2.09302 5V16C2.09302 17.103 3.03174 18 4.18604 18H11.5116V20H8.37209V22H11.5116H13.6046H16.7442V20H13.6046V18H20.9302C22.0845 18 23.0233 17.103 23.0233 16V5C23.0233 3.897 22.0845 3 20.9302 3ZM4.18604 14V5H20.9302L20.9323 14H4.18604Z" fill="#757575"/>
        </svg>
    )
}

export {CalendarIcon, TimeIcon, PeopleIcon, CaseIcon, PeopleBlueIcon, PlayIcon, HandIcon, MonitorIcon }